import React from 'react'
import Logo from '../../images/logos.png'

export default function BusinessSection() {
    return (
        <section className="businesses">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="white_title">Join over 100,000 businesses that rely on Site Booster</h3>
                        <ul className="benifits">
                            <li>Manage and update business details any time and as many times as you need</li>
                            <li>Use photos and a business logo for a more attractive business identity</li>
                            <li>Access a live dashboard that monitors and protects your business identity</li>
                            <li>Need support? our team is here to help 7 days a week, your success is our success</li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-right">
                        <img src={Logo} alt="businesses logo" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
    )
}
