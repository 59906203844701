import React from 'react'

import Header from './Header'
import Footer from './Footer'
import SEO from './SEO'

import '../styles/style.scss'

export default function Layout(props) {
    return (
        <>
            <SEO pageTitle={props.title}/>
            <Header />
            {props.children}
            <Footer />
        </>
    )
}
