import React from 'react'

export default function WebListing() {
    return (
        <section className="web_listing">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="grey_title">
                            Listing your website is an essential <br/>step for your success online!
                        </h2>
                        <div className="video_container">
                            <iframe
                                title="Explanation"
                                src="https://www.youtube.com/embed/Lg49Qb8rchY"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
