import React from 'react'
import { Helmet } from 'react-helmet'

export default function SEO({ pageTitle }) {
    return (
        <Helmet
            title={`${pageTitle} | Site Booster`}
            defer={false}
        />
    )
}
