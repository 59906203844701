import React from 'react'
import CustomerImg from '../../images/get_customer.png'
import SaveTimeMoneyImg from '../../images/save_time_money.png'
import StayControlImg from '../../images/stay_control.png'

export default function ListingImpact() {
    return (
        <section className="listing_impact">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h3 className="white_title">See the impact of listing</h3>
                    </div>
                </div>
                <div className="row mt-70">
                    <div className="col-md-4">
                        <div className="listing">
                            <img src={CustomerImg} alt="get customer" />
                            <h4>Get more customers</h4>
                            <p>
                                Grow your business by showing up where customers are searching and drive more eyeballs and foot traffic your way.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="listing">
                            <img src={SaveTimeMoneyImg} alt="save time & money" />
                            <h4 className="mt-43">Save time & money</h4>
                            <p>
                                Be done in 15 minutes or less and enjoy our service for FREE or upgrade to one of our affordable plans.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="listing">
                            <img src={StayControlImg} alt="stay control" />
                            <h4 className="mt-54">Stay in control</h4>
                            <p>
                                Update your business details any time using Site Booster, and we'll publish the changes all over the internet.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
