import React from 'react'
import SectionImg from '../../images/before_after.jpg'

export default function EndSection() {

    return (
        <section className="before_after">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={SectionImg} className="img-fluid" alt="before/after" />
                    </div>
                </div>
            </div>
        </section>
    )
}
