import React from 'react'
import { Link } from 'gatsby'
import Logo from '../../images/logo.png'
import SearchImg from '../../images/search_img.png'

export default function GetStarted() {
    return (
        <section className="get_started">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={Logo} alt="Site Booster" className="img-fluid logo" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <h1>Get Listed Online</h1>
                        <h2 className="grey_title">Get listed online today for FREE!</h2>
                        <img src={SearchImg} alt="Search" className="img-fluid desktop_none" />
                        <p>
                            Site Booster publishes your business details in all the places that matter online. With just a few easy steps your
                            business can show up on a variety of search engines, review sites, mobile apps and more.
                        </p>
                        <Link to="https://sitebooster.com/?p=sb_ss_y_premium2" className="btn_start">START FREE TRIAL</Link>
                    </div>
                    <div className="col-md-4">
                        <img src={SearchImg} alt="Search" className="img-fluid mobile_none" />
                    </div>
                </div>
            </div>
        </section>
    )
}
