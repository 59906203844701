import React from "react"
import Layout from "../components/Layout"
import GetStarted from "../components/Homepage/GetStarted"
import BusinessSection from "../components/Homepage/BusinessSection"
import WebListing from "../components/Homepage/WebListing"
import ListingImpact from "../components/Homepage/ListingImpact"
import EndSection from "../components/Homepage/EndSection"

export default function Home() {
  return (
    <Layout title="Home">
      <GetStarted />
      <BusinessSection />
      <WebListing />
      <ListingImpact />
      <EndSection />
    </Layout>
  )
}
